$(document).on('shown.bs.modal', '#mainModal', function(e){
    $('#card-element').children('input:first').trigger('focus');

    function stripeTokenHandler(response) {
      var form$ = $('#payment-source-form');
      var token = response['id'];
      var org_id = $('#submit-button').data('organization-id');
      var user_id = $('#submit-button').data('user-id');

      form$.append("<input type='hidden' name='stripeToken' value='" + token + "' />");
      form$.append("<input type='hidden' name='organization_id' value='" + org_id + "' />")
      form$.append("<input type='hidden' name='user_id' value='" + user_id + "' />")
      form$.get(0).submit();
    }

    if($('#card-element').is(':visible')){
      // Create an instance of Elements.
      var elements = stripe.elements();

      // Custom styling can be passed to options when creating an Element.
      // (Note that this demo uses a wider set of styles than the guide below.)
      var style = {
        base: {
          color: '#32325d',
          lineHeight: '18px',
          fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
          fontSmoothing: 'antialiased',
          fontSize: '16px',
          '::placeholder': {
            color: '#2C3E50'
          }
        },
        invalid: {
          color: '#fa755a',
          iconColor: '#fa755a'
        }
      };

      // Create an instance of the card Element.
      var card = elements.create('card', {style: style});

      // Add an instance of the card Element into the `card-element` <div>.
      card.mount('#card-element');

      // Handle real-time validation errors from the card Element.
      card.addEventListener('change', function(event) {
        var displayError = document.getElementById('card-errors');
        if (event.error) {
          displayError.textContent = event.error.message;
          $('#submit-button').attr('disabled', 'disabled')
        } else {
          displayError.textContent = '';
          $('#submit-button').removeAttr('disabled')
        }
      });

      // Handle form submission.
      var form = document.getElementById('payment-source-form');
      form.addEventListener('submit', function(event) {
        event.preventDefault();
        $('#submit-button').attr('disabled', 'disabled')

        stripe.createToken(card).then(function(result) {
          if (result.error) {
            // Inform the user if there was an error.
            var errorElement = document.getElementById('card-errors');
            errorElement.textContent = result.error.message;
          } else {
            // Send the token to your server.
            stripeTokenHandler(result.token);
          }
        });
        return false;
      });
    };
  });
