import 'select2/dist/css/select2.css'
import 'select2'

jQuery(function() {
	$("#organization_id").select2({width: '90%', selectOnClose: true })

  $("#new_admin_ids_").select2({width: '90%', selectOnClose: true })

  $('#multiple_admin_ids').select2({width: '250px', maximumSelectionLength: $('#number_of_admins').val()});
})
