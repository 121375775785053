jQuery(function() {
  $('#admin_users_table').on('click', ".admin-user-sort", function() {
    url = $(this).data('url');
    $.get(url);
  })

  $('#admin_users_table input[type=checkbox][data-toggle^=toggle]').bootstrapToggle();

  $('.admin-users-search').on('keyup', function() {
    value = $(this).val();
    form = $(this).parent('form');
    filter = $(form).find('input#filter');
    console.log(value);
    console.log(form);
    console.log(filter.val());

    $.get(form.attr('action'), { search_value: value, filter: filter.val() });
  })
})