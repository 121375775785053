jQuery(function() {

  // var dataTable =  $($.fn.dataTable.tables(true)).DataTable();
  // if (dataTable !== null) {
  //   dataTable.destroy();
  //   dataTable = null;
  // }

  $('#admin_lessons_table').DataTable({
    "columns": [
      null,
      null,
      null,
      { "orderable": false }
    ]
  });

  $('#admin_games_table').DataTable({
    "columns": [
      null,
      null,
      null,
      { "orderable": false }
    ]
  });

  $('#admin_organizations_table').DataTable({
      "columns": [
        null,
        null,
        null,
        { "orderable": false }
      ]
    });

  $('#admin_org_users_table').DataTable({
    "columns": [
      null,
      null,
      null,
      null,
      null,
      { "orderable": false }
    ],
    "drawCallback": function( settings ) {
      $('input[type=checkbox][data-toggle^=toggle]').bootstrapToggle();
    }
  });

  $('#admin_pages_table').DataTable({
    "columns": [
      null,
      null,
      { "orderable": false }
    ]
  });

  $('#admin_strategies_table').DataTable({
    "columns": [
      null,
      null,
      null,
      { "orderable": false }
    ]
  });

  $('#admin_resource_pages_table').DataTable({
    "columns": [
      null,
      { "orderable": false }
    ]
  });

  $('#admin_support_documents_table').DataTable({
    "columns": [
      null,
      null,
      { "orderable": false }
    ]
  });

  // $('#admin_users_table').DataTable({
  //   "columns": [
  //     null,
  //     null,
  //     null,
  //     null,
  //     null,
  //     null,
  //     null,
  //     { "orderable": false }
  //   ],

  //   "drawCallback": function( settings ) {
  //     $('input[type=checkbox][data-toggle^=toggle]').bootstrapToggle();
  //   }
  // });

  $('#search_results').DataTable({
    "searching": false,
    "columns": [
      null,
      null,
      null,
      null,
      { "orderable": false }
    ]
  })
});

// $(document).on('turbolinks:before-cache', function() {
//   var dataTable =  $($.fn.dataTable.tables(true)).DataTable();
//   if (dataTable !== null) {
//     dataTable.destroy();
//     dataTable = null;
//   }
// });
