jQuery(function() {
  $('input[type=checkbox][name=should_show_districts]:checked').each(function() {
    $('#districts_form_fields').removeClass('d-none').addClass('d-flex');
  });
  $('input[type=checkbox][name=should_show_districts]').change(function () {
    if(this.checked) {
      $('#districts_form_fields').removeClass('d-none').addClass('d-flex');
    } else {
      $('#districts_form_fields').removeClass('d-flex').addClass('d-none');
    }
  });
});
