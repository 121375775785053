jQuery(function() {
  $('.announcement button').on('click', function() {
    $.post({
      url: '/announcements/dismiss',
      data: { announcement_id: $(this).data('announcement') }
    })
    parent = $(this).parent();
    parent.hide();
    sessionStorage.setItem($(this).data('announcement'), 'hide')
  })
})