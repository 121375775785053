import 'select2/dist/css/select2.css'
import 'select2'

jQuery(function() {
  $('input[type="radio"]').on('change', function(event){
    var $super_admin = document.getElementById('user_roles_mask_2').checked;
    if($super_admin){
      $("#user_organization_fields").removeClass('d-block').addClass('d-none');
      document.getElementById("user_organization_id").required = false;
    } else {
      $("#user_organization_fields").removeClass('d-none').addClass('d-block');
      document.getElementById("user_organization_id").required = true;
    }
  });

  $("#new_user").validate({
    rules: {
      organization_id: "required"
    },
    highlight: function (element, errorClass, validClass) {
      var elem = $(element);
      if (elem.hasClass("select2-hidden-accessible")) {
          $("#select2-" + elem.attr("id") + "-container").parent().addClass(errorClass);
      } else {
          elem.addClass(errorClass);
      }
    },
    unhighlight: function (element, errorClass, validClass) {
        var elem = $(element);
        if (elem.hasClass("select2-hidden-accessible")) {
             $("#select2-" + elem.attr("id") + "-container").parent().removeClass(errorClass);
        } else {
            elem.removeClass(errorClass);
        }
    },
    errorPlacement: function(error, element) {
      var elem = $(element);
      if (elem.hasClass("select2-hidden-accessible")) {
          element = $("#select2-" + elem.attr("id") + "-container").parent();
          error.insertAfter(element);
      } else {
          error.insertAfter(element);
      }
    }
  });

  $('select').select2({}).on("change", function (e) {
   $(this).valid()
  });
});
