$ ->
  $('body').on 'change', '#agree_to_terms', (e) ->
    e.preventDefault()
    if(this.checked)
      $('#charge_card_btn').removeClass('disabled')
      $('#charge_card_btn').attr('disabled', false)
    else
      $('#charge_card_btn').addClass('disabled')
      $('#charge_card_btn').attr('disabled', true)

  $('body').on 'submit', '#activate_subscription_form', (event) ->
    event.preventDefault()
    $('#charge_card_btn').attr('disabled', 'disabled')
    $('#charge_card_btn').html($('#charge_card_btn').data('disabled-text'))
    $('#activate_subscription_form').get(0).submit()

  $('body').on 'click', '#apply_coupon', (event) ->
    event.preventDefault()
    $.ajax
      type: 'GET'
      url: $(this).attr('formaction')
      data:
        plan_id: $('#plan_id').val()
        coupon_code: $('input#coupon_code').val()
        organization_id: $('#organization_id').val()
        user_id: $('#user_id').val()

      success: (data, textStatus, jqXHR) ->
        $('#plan-activate-error').addClass('d-none')
        $('#charge_card_btn').text(data['price'])
        $('#apply_coupon').addClass('disabled')

      error: (data, textStatus, jqXHR) ->
        $('#plan-activate-error').text(data.responseText)
        $('#plan-activate-error').removeClass('d-none')

  $('body').on 'change', 'input#coupon_code', () ->
    $('#apply_coupon').removeClass('disabled')

