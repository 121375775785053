import '../src/ckeditor';

jQuery(function() {
  document.querySelectorAll('.ckeditor').forEach(function(val) {
    ClassicEditor.create( val , {
                  simpleUpload: {
                    uploadUrl: '/ckeditor_assets',
                    withCredentials: true,
                    types:  ["jpg", "jpeg", "png", "gif", "tiff", "doc", "docx", "xls", "odt", "ods", "pdf", "rar", "zip", "tar", "tar.gz", "swf"],
                    headers: {
                      'X-CSRF-TOKEN' : $('meta[name="csrf-token"]').attr("content")
                    }
                  }
                })
                .then( editor => {
                  window.editor = editor;
                })
                .catch( error => {
                  console.error('There was a problem initializing the editor.')
                  console.log(error)
                })
  })
})
