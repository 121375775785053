require("@rails/ujs").start();
// require("turbolinks").start();

require("bootstrap");
require("bootstrap-toggle");
import "@fortawesome/fontawesome-free/js/all";

require("jquery");
require("datatables.net-dt")(window, $);
import dt from "datatables.net";

// document.addEventListener("turbolinks:load", () => {
//   dt(window, $);
// });

import 'src/styles/application.scss';
const images = require.context('../images', true);

import I18n from 'i18n-js';
window.I18n = I18n;

import "jquery-validation/dist/jquery.validate";
import "../src/accounts";
import "../src/contact_forms";
import "../src/ga";
import "../src/init_ckeditor";
//import "../src/interact";
import "../src/lessons";
import "../src/modal";
import "../src/organizations";
import "../src/payment_sources";
import "../src/strategies";
import "../src/select";
import "../src/stripe_subscription";
import "../src/support_documents";
import "../src/header";
import "../src/users";

import('../src/games/35reveal');
import('../src/games/k2reveal');
import('../src/games/35gender');
import('../src/games/k2gender');
import('../src/games/vortex');
import('../src/games/world_culture_awareness');

import "@fortawesome/fontawesome-free/js/all";

//Initializers
import "../src/initializers/datatables"
import "../src/initializers/select2"

//Utils
import "../src/utils/content-links"
import "../src/utils/hide_districts_content"
import "../src/utils/scroll_to_top"
import "../src/utils/set_hash_value"
import "../src/utils/toggle_active_user"
import "../src/utils/toggle_charge_card_button"
import "../src/utils/toggle_organization_fields"
