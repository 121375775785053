import 'select2/dist/css/select2.css'
import 'select2'

jQuery(function() {
  initialize_select2_elements();
  $("form").each(function() {
     $(this).validate();
  });
  function initialize_select2_elements() {
    $("#lesson_grade_levels").select2({ width: '250px', placeholder:
      I18n.t('activerecord.attributes.lesson.grade_levels') });
    $("#lesson_common_core_standards").select2({ width: '220px', placeholder:
      I18n.t('activerecord.attributes.lesson.select_common_core_standards') });
    $("#lesson_topics").select2({ width: 'resolve', placeholder:
      I18n.t('activerecord.attributes.lesson.select_topics') });
    $("#lesson_strategies").select2({ width: '250px', placeholder:
      I18n.t('activerecord.attributes.lesson.select_strategies') });
    $("#lesson_support_documents").select2({width: '250px', placeholder:
      I18n.t('activerecord.attributes.lesson.select_support_documents') });
    $("#game_grade_levels").select2({width: '100%', placeholder:
      I18n.t('activerecord.attributes.lesson.select_grade_levels') });
    $("#game_topics").select2({width: 'resolve', placeholder:
      I18n.t('activerecord.attributes.lesson.select_topics') });
    $("#game_support_documents").select2({width: '250px', placeholder:
      I18n.t('activerecord.attributes.lesson.select_support_documents') });
    $("#organization_address_attributes_state_province").select2();
    $("#organization_school_district_attributes_name").select2({width: '300px', placeholder:
      I18n.t('activerecord.attributes.lesson.school_district'), tags: true, allowClear: true, selectOnClose: true }).on("select2:select", function (e) {
      var selected_district_number = $(e.currentTarget).find(':selected').data('district-number')
      var selected_district_id =
      $(e.currentTarget).find(':selected').data('district-id')
      $("#organization_school_district_attributes_id").val(selected_district_id)
      $("#organization_school_district_attributes_number").val(selected_district_number)
    });

    $("#signup_school_district_name").select2({width: '300px', placeholder:
      I18n.t('activerecord.attributes.lesson.school_district'), tags: true, allowClear: true, selectOnClose: true }).on("select2:select", function (e) {
      var selected_district_number = $(e.currentTarget).find(':selected').data('district-number')
      $("#signup_school_district_number").val(selected_district_number)
    });

    //TODO: Fix this properly so it doesn't interfere with the non-admin user invitations.
    if ($('.admin-main').length > 0) {
      var selected_org_id = $('#user_organization_id').data('organization-id')
      $("#user_organization_id").select2({width: '300px', placeholder: I18n.t('organization.organization'), allowClear: true, selectOnClose: true})
      $('#user_organization_id').val(String(selected_org_id))
      if(selected_org_id != null) {
        $('#user_organization_id').trigger('change')
      }
    }
  }

  $('input[type=checkbox][data-toggle^=toggle]').bootstrapToggle()
  $('.select2-hidden-accessible').select2('destroy');
});
