jQuery(function() {
  var adminToggleUserActiveState = function(e, elem){
    $.ajax({
      url: '/admin/users/'+elem.value+'/toggle_active',
      type: 'PATCH',
      dataType: 'json',
      data: { "user": { "active": elem.checked } },
      error: function(data) {
        $(elem).bootstrapToggle('off');
        alert(data.responseText);
      }
    });
    e.stopImmediatePropagation();
    return false;
  }

  $("[name='admin_active_user_toggle']").on('change', function(e){
    adminToggleUserActiveState(e, this);
  });

  var toggleUserActiveState = function(e, elem){
    $.ajax({
      url: '/user/toggle_active?' + $.param({ id: elem.value }),
      type: 'PATCH',
      data: { "user": { "active": elem.checked } },
      error: function(data) {
        $(elem).bootstrapToggle('off');
        alert(data.responseText);
      }
    });
    e.stopImmediatePropagation();
    return false;
  }

  $("[name='active_user_toggle']").on('change', function(e){
    toggleUserActiveState(e, this);
  });

  $('#admin_users_table, #admin_org_users_table').on( 'draw.dt', function () {
    $("[name='admin_active_user_toggle']").on('change', function(e){
      adminToggleUserActiveState(e, this);
    });

    $("[name='active_user_toggle']").on('change', function(e){
      toggleUserActiveState(e, this);
    });
  });
});
