jQuery(function () {
  $('#new_organization').validate({
    rules: {
      organization_name: "required",
      organization_plan_id: "required"
    },
    highlight: function (element, errorClass, validClass) {
      var elem = $(element);
      if (elem.hasClass("select2-hidden-accessible")) {
        $("#select2-" + elem.attr("id") + "-container").parent().addClass(errorClass);
      } else {
        elem.addClass(errorClass);
      }
    },
    unhighlight: function (element, errorClass, validClass) {
      var elem = $(element);
      if (elem.hasClass("select2-hidden-accessible")) {
        $("#select2-" + elem.attr("id") + "-container").parent().removeClass(errorClass);
      } else {
        elem.removeClass(errorClass);
      }
    },
    errorPlacement: function (error, element) {
      var elem = $(element);
      if (elem.hasClass("select2-hidden-accessible")) {
        element = $("#select2-" + elem.attr("id") + "-container").parent();
        error.insertAfter(element);
      } else {
        error.insertAfter(element);
      }
    }
  })

  $('#new_organization').on('submit', function (e) {
    $(this).valid();
  })

  $('[id*="edit_organization_"]').validate({
    rules: {
      organization_name: {
        required: true
      },
      "organization_address_attributes_postal_code": "required"
    }
  })

  $('[id*="edit_organization_"]').on('submit', function (e) {
    $(this).valid();
  })


  $('#new_signup').validate({
    submitHandler: function (form) {
      fbq('track', 'SubmitApplication');
      form.submit();
    },
    errorPlacement: function (error, element) {
      if (element.attr('name') == 'signup[plan_id]') {
        console.log(element)
        elem = $('.plans > span:last')
        error.insertAfter(elem)
      } else {
        error.insertAfter(element);
      }
    }
  })

  $('#new_user_signup').validate({
    submitHandler: function (form) {
      fbq('track', 'SubmitApplication');
      form.submit();
    }
  })

  $("#signup_free_trial").on('click', function (e) {
    if ($(this).is(':checked')) {
      $('#free-trial-date').removeClass('d-none');
    } else {
      $('#free-trial-date').addClass('d-none');
    }
  })

})
